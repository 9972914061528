<template>
  <div class="artistRegister bgc_ffffff width_1200">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="头像" prop="HeadImg">
        <el-upload
          class="avatar-uploader"
          :limit="1"
          :headers="imgHeaders"
          :http-request="afterRead"
          :action="imagesUploadPic1 + 'headImg'"
          :show-file-list="false"
        >
          <img v-if="formData.HeadImg" :src="formData.HeadImg" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="姓名" prop="SupplierName">
        <el-input
          v-model="formData.SupplierName"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="Phone">
        <el-input
          v-model="formData.Phone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="Sex">
        <el-select v-model="formData.Sex" placeholder="请选择性别">
          <el-option
            :label="c"
            :value="c"
            v-for="(c, i) in columns"
            :key="i"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生年月" prop="Birth">
        <el-date-picker
          type="date"
          placeholder="请选择出生年月"
          v-model="formData.Birth"
          format="yyyy/MM/dd"
          value-format="yyyy/MM/dd"
          :picker-options="pickerOptions"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="地区选择" prop="NativePlace">
        <el-cascader
          size="large"
          :options="regionData"
          v-model="areas"
          @change="handleChangeRegion"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="毕业院校" prop="GraduationSchool">
        <el-input
          v-model="formData.GraduationSchool"
          placeholder="请输入毕业院校"
        ></el-input>
      </el-form-item>
      <el-form-item label="专业" prop="Major">
        <el-input v-model="formData.Major" placeholder="请输入专业"></el-input>
      </el-form-item>
      <el-form-item label="学历" prop="EducationRecord">
        <el-select v-model="formData.EducationRecord" placeholder="请选择学历">
          <el-option
            :label="c"
            :value="c"
            v-for="(c, i) in educationColumns"
            :key="i"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任职机构" prop="WorkOrganization">
        <el-input
          v-model="formData.WorkOrganization"
          placeholder="请输入任职机构"
        ></el-input>
      </el-form-item>
      <el-form-item label="职称" prop="Positional">
        <el-input
          v-model="formData.Positional"
          placeholder="请输入职称"
        ></el-input>
      </el-form-item>
      <el-form-item label="头衔" prop="Titles">
        <el-input
          v-model="formData.Titles"
          placeholder="多个头衔请用逗号,隔开"
        ></el-input>
      </el-form-item>
      <el-form-item label="师承" prop="TeacherInfo">
        <el-input
          v-model="formData.TeacherInfo"
          placeholder="请输入师承"
        ></el-input>
      </el-form-item>
      <el-form-item label="个人简介" prop="Remark">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
          v-model="formData.Remark"
          placeholder="请输入个人简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="代表作品" prop="Introduce">
        <el-upload
          multiple
          :headers="imgHeaders"
          :action="imagesUploadPic1 + 'Images'"
          list-type="picture-card"
          :http-request="afterRead"
          :file-list="fileList"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { artistRegister, querySupplierByStudentId } from "@/api/art";
import { imagesUploadPic } from "@/api/api";
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  data () {
    var validateHeadImg = (rule, value, callback) => {
      console.log(value, '')
      if (value === '') {
        callback(new Error('请选择头像'));
      } else {
        callback();
      }
    };
    return {
      regionData,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imagesUploadPic1: "",
      imgHeaders: { "Content-Type": "multipart/form-data" },
      areaList,
      resd: {},
      username: "",
      password: "",
      value: "",
      columns: ["男", "女", "保密"],
      educationColumns: ["本科", "硕士", "博士", "博士后", "其他"],
      showPicker: false,
      showCalendar: false,
      showEducation: false,
      showArea: false,
      minDate: new Date(1921, 0, 1),
      defaultDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      formData: {
        Id: "0",
        EducationRecord: "",
        SupplierName: "",
        Phone: "",
        Birth: "",
        NativePlace: "",
        Major: "",
        WorkOrganization: "",
        GraduationSchool: "",
        Sex: "",
        Positional: "",
        Titles: "",
        TeacherInfo: "",
        HeadImg: "",
        Remark: "",
      },
      areas: [],
      headImgList: [],
      imgList: [],
      fileList: [],
      route: {},
      artUserId: "",
      rules: {
        HeadImg: [
          { validator: validateHeadImg, trigger: 'blur' },
        ],
        SupplierName: [
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        Phone: [
          { required: true, message: '请输入联系电话', trigger: 'change' }
        ],
      }
    };
  },
  created () {
  },
  mounted () {
    this.route = this.$route.query;
    this.artUserId = localStorage.getItem("artUserId");
    // console.log(this.route.UserID, '')
    this.queryArtistDetail();
  },
  methods: {
    // TextToCode是个大对象，属性是汉字，属性值是区域码 用法例如：TextToCode['北京市'].code输出110000,TextToCode['北京市']['市辖区'].code输出110100,TextToCode['北京市']['市辖区']['朝阳区'].code输出110105
    convertTextToCode (provinceText, cityText, regionText) {
      let code = "";
      if (provinceText && this.TextToCode[provinceText]) {
        const province = this.TextToCode[provinceText];
        code += province.code + ", ";
        if (cityText && province[cityText]) {
          const city = province[cityText];
          code += city.code + ", ";
          if (regionText && city[regionText]) {
            code += city[regionText].code;
          }
        }
      }
      return code;
    },
    handleChangeRegion (value) {
      // CodeToText是个大对象，属性是区域码，属性值是汉字 用法例如：CodeToText['110000']输出北京市
      let str = CodeToText[value[0]] + '/' + CodeToText[value[1]] + '/' + CodeToText[value[2]]
      this.formData.NativePlace = str
    },
    // 放大
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 删除图片
    handleRemove (file) {
      // console.log(file);
      this.fileList = this.fileList.filter(element => {
        return element.url != file.url
      });
      this.imgList = this.imgList.filter(element => {
        return element != file.url
      });
    },
    onConfirm (value) {
      this.formData.Sex = value;
      this.showPicker = false;
    },
    onselectBirth (date) {
      this.formData.Birth = this.formatDate(date);
      this.showCalendar = false;
    },
    onselectEducation (value) {
      this.formData.EducationRecord = value;
      this.showEducation = false;
    },
    onselectArea (values) {
      this.formData.NativePlace = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.onSubmit()
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    async onSubmit (values) {
      this.$set(this.formData, "SupplierCategory", 1);
      this.$set(this.formData, "StudentId", this.artUserId);
      this.$set(this.formData, "ArtistWorkImgs", this.imgList.join(","));
      // this.formData.SupplierCategory = 1;
      // this.formData.StudentId = this.route.UserID;
      // this.formData.ArtistWorkImgs = this.imgList.join(",");
      delete this.formData.headImgList;
      const res = await artistRegister(this.formData);
      this.resd = res.data;
      if (res.data.success == true) {
        if (this.formData.Id > 0) {
          this.$message.success("信息修改成功");
        } else {
          this.$message.success("信息录入成功，等待审核");
        }
      } else {
        this.$message.error("网络错误，请稍后重试");
      }
      console.log(res);
    },
    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    async queryArtistDetail () {
      const res = await querySupplierByStudentId(
        "?studentId=" + this.route.UserID
      );
      if (res.data.response != null) {
        this.formData = res.data.response;
        var imgages = res.data.response.ArtistWorkImgs ? res.data.response.ArtistWorkImgs.split(",") : [];
        this.formData.Remark = this.formData.Remark.replace(/<[^>]+>/g, '')
        let arr = this.formData.NativePlace.split('/')
        if (arr[1] === '北京市' || arr[1] === '天津市' || arr[1] === '重庆市' || arr[1] === '上海市') {
          this.$set(arr, 1, '市辖区')
        }
        this.areas = [
          TextToCode[arr[0]].code + "",
          TextToCode[arr[0]][arr[1]].code + "",
          TextToCode[arr[0]][arr[1]][arr[2]].code + "",
        ]
        for (let index = 0; index < imgages.length; index++) {
          this.imgList.push(imgages[index]);
          this.fileList.push({ url: imgages[index] });
        }
        if (
          res.data.response.HeadImg != null &&
          res.data.response.HeadImg != ""
        ) {
          this.headImgList.push({ url: res.data.response.HeadImg });
        }
      }
    },
    async postArtRegister () {
      let parm = {
        courseId: this.courseId,
        studentId: this.route.UserID,
        pageIndex: this.page,
        pageSize: this.pageSize,
        answer: this.answer,
      };
      const res = await artistRegister(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    afterRead (file, name) {
      file.file.uploadName = file.action;

      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic (file) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(file); //图片压缩
      formData.append("file", newFile);
      formData.append("progame", "ArtistWork");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        if (file.uploadName == "Images") {
          this.imgList.push(url);
          this.fileList.push({ url: url });
        } else {
          this.formData.HeadImg = url;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    afterdelete (file) {
      console.log(file);
      if (file.file == null) {
        var index = this.imgList.indexOf(file.url);
        console.log(index);
        if (index != -1) {
          this.imgList.splice(this.imgList.indexOf(file.url), 1);
        } else {
          this.formData.HeadImg = "";
        }
        console.log(index);
      } else {
        //删除数组中的删除元素
        if (file.uploadName == "Images") {
          this.imgList.splice(this.imgList.indexOf(file.file.serverUrl), 1);
        } else {
          this.formData.HeadImg = "";
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.artistRegister {
  // max-width: 750px;
  margin: 50px auto;
  padding: 50px 150px;
}
/deep/.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
  }
}
</style>